import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Math from "../components/Math"

const MathPage = () => (
  <Layout>
    {
      // eslint-disable-next-line
      <SEO
        title="Калькулятор"
        description="Калькулятор для скупки катализаторов"
      />
    }
    <div className="pt-20 lg:pt-16">
      <section
        id="calc"
        aria-labelledby="calc-heading"
        className="mx-auto max-w-xl py-6 px-0 sm:py-24 md:px-12 lg:max-w-7xl"
      >
        <h1
          id="calc-heading"
          className="text-center text-4xl font-extrabold md:text-5xl"
        >
          Калькулятор
        </h1>
        <h2 className="py-2 text-center text-xl font-light md:text-2xl">
          для выездных менеджеров и пунктов приема катализаторов
        </h2>
        <Math />
      </section>
    </div>
  </Layout>
)

export default MathPage
